<script setup lang="ts">
const workspaceStore = useWorkspaceStore();
import WorkspaceDropdown from '~/components/account/WorkspaceDropdown.vue';

const { workspace, subscriptionType } = storeToRefs(workspaceStore);
const { routeName } = useRouteName();

const accountItems = [
  {
    label: 'My Profile',
    to: '/account/profile',
    icon:
      routeName.value === 'profile'
        ? 'i-ph-user-circle-fill'
        : 'i-ph-user-circle',
  },
  {
    label: 'Linked Accounts',
    to: '/account/linked-accounts',
    icon:
      routeName.value === 'linkedAccounts'
        ? 'i-ph-link-simple-bold'
        : 'i-ph-link-simple',
  },
];

const workspaceItems = computed(() => {
  const items = [
    {
      label: 'Plans and Billing',
      to: '/account/billing',
      icon:
        routeName.value === 'billing'
          ? 'i-ph-credit-card-fill'
          : 'i-ph-credit-card',
    },
    {
      label: 'Members',
      to: '/account/members',
      icon:
        routeName.value === 'members'
          ? 'i-ph-users-four-fill'
          : 'i-ph-users-four',
    },
  ];

  if (subscriptionType.value === 'enterprise') {
    items.push({
      label: 'Settings',
      to: '/account/settings',
      icon: routeName.value === 'settings' ? 'i-ph-gear-fill' : 'i-ph-gear',
    });
  }

  return items;
});
</script>
<template>
  <div
    class="w-72 shrink-0 h-full flex flex-col justify-start items-start border-r border-gray-200"
  >
    <HomeTopbar context="account" />

    <div v-if="workspace?.id" class="px-m pb-l pt-m w-full">
      <p class="title pl-s">My Account</p>
      <UVerticalNavigation :links="accountItems" class="mt-m" />
      <p class="title pl-s">Workspace</p>
      <WorkspaceDropdown />
      <UVerticalNavigation :links="workspaceItems" class="mt-m" />
    </div>
  </div>
</template>
